globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"f1a4013ee677ee896d0b52f81386c1eebcd4a2cd"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://57e28ff867674cb5a81beec4d19501cb@o4504600046534656.ingest.us.sentry.io/4504600051712000',

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});
